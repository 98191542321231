import React from 'react'
import { Link } from 'gatsby'

import { Container, Row, Col } from 'react-bootstrap';

import { Facebook, Google } from 'react-bootstrap-icons';

export default () => {
    return(
        <>
        <style>{`
            .footer {
                background-color: #07260E;
                color: #E9F4EB;
            }
            .footer-logo {
                font-family: 'Nothing You Could Do', cursive;
                font-weight: 700;
                font-size: 30px;
                color: #fff !important;
                line-height: 1;
            }
            .footer-logo:hover {
                text-decoration: none;
            }
            .footer-copyright {
                font-weight: 300;
                font-size: 16px;
                line-height: 1;
            }
            .footer ul {
                margin: 0;
                padding: 0;
                list-style: none;
            }
            .footer ul li {
                display: inline-block;
                margin-right: 18px;
                margin-left: 18px;
            }
            .footer ul li a {
                font-size: 17px;
                font-weight: 500;
                line-height: 1;
                text-decoration: none;
                letter-spacing: 1px;
                font-weight: 400;
                color: #E9F4EB;
            }
            .footer ul li a:hover {
                text-decoration: none;
                opacity: 0.8;
            }
            @media(min-width: 768px) {
                .footer {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }
            @media(max-width: 767px) {
                .footer {
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
        `}</style>
        <div className="section footer">
            <Container>
                <Row>
                    <Col md={12} className="text-center">
                        <div className="mb-4">
                            <Link to={`/`} className="footer-logo">Sumo Japanese Restaurant</Link>
                        </div>

                        <ul className="mb-4">
                            <li><Link to={`/`}>Home</Link></li>
                            {/*<li><Link to={`/news`}>News + Events</Link></li>*/}
                            <li><Link to={`/about`}>About us</Link></li>
                            <li><Link to={`/contact`}>Contact us</Link></li>
                        </ul>

                        <ul className="mb-4">
                            <li><a aria-label="Facebook" target="_blank" rel="noreferrer" href="https://www.facebook.com/Sumo-Sushi-438125709564928"><Facebook /></a></li>
                            <li><a aria-label="Yelp" target="_blank" rel="noreferrer" href="https://www.yelp.com/biz/sumo-sushi-albany"><i>Y</i>elp</a></li>
                            <li><a aria-label="Google" target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Sumo+Japanese+Restaurant/@37.8875454,-122.3000249,17z/data=!3m1!4b1!4m5!3m4!1s0x80857932f3b4ac9f:0xb617184f523f4474!8m2!3d37.8875454!4d-122.2978362"><Google /></a></li>
                        </ul>

                        <p className="footer-copyright">
                            © {new Date().getFullYear()} Sumo Japanese Restaurant. All rights reserved.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    )
}
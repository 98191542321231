import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { House, CreditCard } from 'react-bootstrap-icons'
import { Navbar, Nav, Modal, /*Button*/ } from 'react-bootstrap';

import { X } from 'react-bootstrap-icons';

import Footer from '../components/views/footer'

export default (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    /*const handleShow = () => setShow(true);*/

    const data = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                title
              }
            }
          }
        `
    )

    return (
        <>
            <style>
            {`
                .bg-dark {
                    background: rgba(21, 114, 43, .9) !important;
                }
                .navbar {
                    padding: 10px 40px;
                    box-shadow: 0 6px 15px 2px rgba(0, 0, 0, 0.12);
                }
                .navbar-brand {
                    font-family: 'Nothing You Could Do', cursive;
                    font-weight: 400;
                    font-size: 30px;
                    color: #fff !important;
                }
                .navbar-light .navbar-nav .nav-link {
                    color: #fff;
                    font-size: 16px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-weight: 700;
                }
                .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
                    color: #fff;
                    opacity: 0.7;
                }
                .navbar-light .navbar-toggler-icon {
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(247,230,225, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
                }
                .navbar-light .navbar-toggler {
                    border: none;
                    outline: 0;
                    padding: 0;
                    color: #28a745;
                }
                .dropdown-menu { 
                    border-radius: 0px;
                    padding: 0;
                    box-shadow: 0 6px 15px 2px rgba(57, 48, 96, 0.12);
                    border: none;
                    overflow: hidden;
                }
                .dropdown-item {
                    font-size: 18px;
                    padding: 10px 25px;
                    font-weight: 300;
                }
                .dropdown-item:hover, .dropdown-item:focus {
                    color: #fff;
                    background-color: #F5764E;
                }
                @media(min-width: 992px) {
                    .navbar-light .navbar-nav .nav-link {
                        padding: 0 24px;
                    }
                    .navbar-btn-right {
                        margin-left: 30px;
                    }
                    .dropdown-menu {
                        top: 150%;
                        left: 24px;
                    }
                }
                @media(max-width: 991px) {
                    .navbar-light .navbar-nav .nav-link {
                        margin-bottom: 12px;
                    }
                    .navbar-collapse {
                        padding-top: 20px;
                        padding-bottom: 20px;
                    }
                }
                .btn-navbar-custom {
                    padding: 10px 30px;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    font-size: 15px;
                    color: #fff !important;
                    border-radius: 10px;
                    line-height: 1;
                }
                .close-button {
                    position: absolute;
                    top: 10px;
                    right: 13px;
                    font-size: 30px;
                    cursor: pointer;
                    z-index: 100;
                    border: 0;
                    background-color: #fff;
                    padding: 0;
                }
            `}
            </style>
            <Navbar bg="dark" expand="lg" fixed="top">
                <Link to={`/`}>
                    <Navbar.Brand>
                        {data.site.siteMetadata.title}
                    </Navbar.Brand>
                </Link>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav><a style={{color: '#fff', fontWeight: 'bold'}} className="nav-link" href="tel:+1 (510) 524-4700">+1 (510) 524-4700</a></Nav>
                        <Nav>
                            <Link className="nav-link" to={`/`}>
                                <House /> Home
                            </Link>
                        </Nav>
                        {/*<Nav><Link className="nav-link" to={`/news`}>News + Events</Link></Nav>*/}
                        <Nav><Link className="nav-link" to={`/about`}>About us</Link></Nav>
                        <Nav><Link className="nav-link" to={`/contact`}>Contact us</Link></Nav>
                    </Nav>
                    {/*<Button className="navbar-btn-right btn btn-primary btn-navbar-custom" onClick={handleShow}>Order online</Button>*/}
                    <a target="_blank" rel="noreferrer" href="https://www.beyondmenu.com/48111/albany/sumo-sushi-restaurant-albany-94706.aspx" className="navbar-btn-right btn btn-primary btn-navbar-custom"><CreditCard /> Order online</a>
                </Navbar.Collapse>
            </Navbar>
            {props.children}
            <Footer />
            <Modal show={show} onHide={handleClose} animation={false}>
                <button type="button" className="close-button" onClick={handleClose} aria-label="close"><X /></button>
                <Modal.Body className="text-center">
                    
                </Modal.Body>
            </Modal>
        </>
    )
}